import React from 'react'
import {graphql} from "gatsby"
import EmailModal from '../components/EmailModal'
import Breadcrumbs from '../components/Breadcrumbs'
import ContactForm from '../components/ContactForm'
import ImageHeader from '../components/ImageHeader'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import '../styles/content.scss'

const PageTemplate = ({data}) => {
    const image = data.wpPage.featuredImage?.node.mediaDetails?.sizes.find(size => size.name === 'large')?.sourceUrl
    const title = data.wpPage.title
      return (
          <Layout>
              <Seo
                  title={title}
                  description={data.wpPage.excerpt}
              />
              <ImageHeader title={title}
                           image={image ? image : ''}/>
              <Breadcrumbs isResource={false}
                           title={title}
              />
              <div className="container page-content" dangerouslySetInnerHTML={{__html: data.wpPage.content}}/>
              {title === 'Get Updates' &&
                <EmailModal />
              }
              {title === 'Contact' &&
                <ContactForm />
              }
          </Layout>
          )
}

export default PageTemplate

export const query = graphql`
    query($id: Int!) {
        wpPage(databaseId: {eq: $id}) {
            title
            content
            featuredImage {
                node {
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
`
